import { EmailCTA } from "./primitives/cta";
import { EmailFooter } from "./primitives/footer";
import { EmailHighlightBlock } from "./primitives/highlight-block";
import { colors } from "./tokens";

interface Props {
  token: string;
  name: string;
}
const URL = import.meta.env.VITE_DEPLOY_URL;

export function WelcomeEmail(props: Props) {
  return (
    <>
      <style>
        {`
        @media only screen and (max-width: 600px) {
            .container {
                width: 100% !important;
            }
            .content {
                padding: 20px !important;
            }
            .cta-button {
                font-size: 18px !important;
                padding: 12px 24px !important;
            }
        }
            `}
      </style>
      <div
        style={{
          margin: 0,
          padding: 0,
          "font-family": "Arial, sans-serif",
          color: colors.text,
          "background-color": "#f4f4f4",
          "-webkit-font-smoothing": "antialiased",
          "-moz-osx-font-smoothing": "grayscale",
        }}
      >
        <div
          class="container"
          style={`max-width: 600px; margin: 0 auto; background-color: ${colors.accentText};`}
        >
          <div
            style={{
              "text-align": "center",
              padding: "40px 0 30px 0",
              background: colors.mutedColor,
            }}
          >
            <img
              src={`${URL}/atilaio.png`}
              alt="Atila.io logo"
              style={{
                width: "100px",
                display: "inline-block",
              }}
            />
          </div>
          <div class="content" style="padding: 36px 30px 0 30px;">
            {/* <h1 style="font-size: 24px; margin: 0 0 20px 0; font-family: Arial, sans-serif;">
              Welcome to Our Exclusive Waitlist!
            </h1> */}
            <p style="margin: 0 0 12px 0; font-size: 16px; line-height: 24px;">
              Hey {props?.name || "Friend"},
            </p>
            <p style="margin: 0 0 12px 0; font-size: 16px; line-height: 24px;">
              When building yourself a brand new thing, a community is the most
              important thing to get it right. A <b>robust waitlist</b>,
              unafraid of growing that can be set up in 3 minutes is a must!
            </p>
            <EmailCTA href={`${URL}/verify/${props.token}`}>
              Verify your Email
            </EmailCTA>
            <EmailHighlightBlock />

            <p style="margin: 36px 0  24px; font-size: 24px; line-height: 24px;">
              Reasons I'd email you again:
            </p>
            <ul style="margin: 0 0 12px 0; padding-left: 20px; list-style: none; font-size: 16px;">
              <li style="margin: 0 0 8px 0;">
                📫 News about <b>W8</b> being ready to onboard you!
              </li>
              <li style="margin: 0 0 8px 0;">
                🔥 Upcoming launches to help your indie adventures.
              </li>
              <li style="margin: 0 0 8px 0;">
                ❤️‍🔥 Cool open-source projects and templates to speed you up!
              </li>
            </ul>
            <p style="margin: 32px 0 0 0; font-size: 16px; line-height: 24px;">
              The wait is almost over because <b>W8</b> is almost ready!
            </p>
            <p style="margin: 32px 0 36px 0; font-size: 16px; line-height: 24px;">
              Talk soon 👋
            </p>

            <EmailFooter />
          </div>
        </div>
      </div>
    </>
  );
}
